import { StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { AllStoreSlices, LeadsSlice } from '../StoreSlices';
import { Lead } from 'components/types/interfaces';

export const createLeadsSlice: StateCreator<
  AllStoreSlices,
  [['zustand/immer', never]],
  [['zustand/immer', never]],
  LeadsSlice
> = immer(set => ({
  leads: [],
  selectedLead: null,
  setLeads: (leads: Lead[]): void =>
    set(state => {
      state.leads = leads.map((lead, i) => ({ ...lead, currentIndex: i }));
      state.selectedLead = null;
    }),
  setSelectedLead: (leadIndex: number | null): void =>
    set(state => {
      state.selectedLead = leadIndex !== null ? state.leads[leadIndex] : null;
    }),
  updateLead: (updatedLead: Lead): void =>
    set(state => {
      const index = state.leads.findIndex(lead => lead.leadId === updatedLead.leadId);
      if (index !== -1) {
        state.leads[index] = updatedLead;
      }
    })
}));
