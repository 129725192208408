/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { Map, MapLayersControl, ZoomTool, AdvancedMarker, Loading } from '@costar/land-ui-components';
import { useParams } from 'react-router-dom';
import styles from './SimpleMapNew.module.scss';

export interface EditListingParamType {
  id: string;
}

export interface ChildProps {
  children?: React.ReactNode;
}

export interface MapLocationType extends ChildProps {
  isMapApiLoaded: boolean;
  location: google.maps.LatLngLiteral;
  viewport?: google.maps.LatLngBoundsLiteral;
  saveMarker: (pos: google.maps.LatLngLiteral) => void;
}

const defaultCenter = { lat: 39.90973623453719, lng: -98.7890625 };
const defaultZoom = 4;
const markerZoom = 16;
const mapOptions = {
  center: defaultCenter,
  zoom: defaultZoom,
  fullscreenControl: false,
  streetViewControl: false,
  gestureHandling: 'greedy',
  mapTypeControl: false,
  scaleControl: true,
  zoomControl: false,
  mapId: 'd2b90eb367a44258f'
};

export default function SimpleMapNew({ isMapApiLoaded, location, viewport }: MapLocationType): JSX.Element {
  useParams<EditListingParamType>();

  const [listingDetailsMap, setListingDetailsMap] = useState<google.maps.Map | undefined>(undefined);
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral | null>(null);

  const isGoodLocation = (pos: google.maps.LatLngLiteral | null | undefined): boolean => {
    return !!(pos && pos.lat && pos.lng);
  };

  const updateMarkerAndMapPosition = useCallback(
    (locationPosition: MapLocationType['location'], locationViewport: MapLocationType['viewport'] | null) => {
      if (!listingDetailsMap) return;
      if (isGoodLocation(locationPosition)) {
        if (locationViewport) {
          listingDetailsMap.fitBounds(locationViewport);
        } else {
          listingDetailsMap.panTo(locationPosition);
          listingDetailsMap.setZoom(markerZoom);
        }
        setMarkerPosition(locationPosition);
      } else {
        listingDetailsMap.panTo(defaultCenter);
        listingDetailsMap.setZoom(defaultZoom);
      }
    },
    [listingDetailsMap]
  );

  useEffect(() => {
    if (isMapApiLoaded && listingDetailsMap) {
      updateMarkerAndMapPosition(location, viewport);
    }
  }, [isMapApiLoaded, listingDetailsMap, location, viewport, updateMarkerAndMapPosition]);

  return isMapApiLoaded ? (
    <div className={styles.container}>
      <Map key={markerPosition as any} options={mapOptions} setParentMap={setListingDetailsMap} className={styles.map}>
        <MapLayersControl windowWidth={1601} />
        {markerPosition && (
          <AdvancedMarker
            marker={{
              position: markerPosition,
              title: 'Property Location'
            }}
          />
        )}
        <ZoomTool map={listingDetailsMap} position={'LEFT_TOP'} zoom={defaultZoom} className={styles['zoom-wrapper']} />
      </Map>
    </div>
  ) : (
    <Loading />
  );
}
