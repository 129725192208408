import { fetchLandApiData, postToLandApi } from './helpers';

export interface FeatureFlagInfo {
  siteId: number;
  flag: string;
  value: boolean;
}

export enum FlagKeys {
  Auction = 'show_auction_details_on_marketing_hub_long_form',
  ShowListingSpecificAnalyticsPage = 'show_listing_specific_analytics_page',
  InjectMazeTrackingScript = 'inject_maze_tracking_script',
  UseStripePaymentMH = 'configuration_use_stripe_for_payment_token_in_marketing_hub',
  ShowNewMapOnLongForm = 'show_new_map_on_marketing_hub_long_form'
}

export const defaultFlags: Record<FlagKeys, boolean> = {
  [FlagKeys.Auction]: false,
  [FlagKeys.ShowListingSpecificAnalyticsPage]: false,
  [FlagKeys.InjectMazeTrackingScript]: false,
  [FlagKeys.UseStripePaymentMH]: false,
  [FlagKeys.ShowNewMapOnLongForm]: false
};

export const getFeatureFlagForUser = async (flagKey: FlagKeys, customAttributes = []): Promise<boolean> => {
  try {
    const response = await postToLandApi<boolean>(`/featureflag/${flagKey}/3000/user`, customAttributes);

    if (response.ok) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return false;
};

export const getFeatureFlagForSite = async (flagKey: FlagKeys): Promise<boolean> => {
  try {
    const response = await fetchLandApiData<boolean>('', `/featureflag/${flagKey}/3000`);

    if (response.ok) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return false;
};

export const getAllFlagsForSite = async (): Promise<Record<FlagKeys, boolean>> => {
  try {
    const response = await fetchLandApiData<Record<FlagKeys, boolean>>('', `/featureflag/all/3000`);

    if (response.ok) {
      const apiResponse = response.values;
      const flagKeyObj = {} as Record<FlagKeys, boolean>;
      Object.values(FlagKeys).forEach(flag => {
        flagKeyObj[flag as FlagKeys] = apiResponse[flag];
      });

      return flagKeyObj;
    }
  } catch (error) {
    console.error(error);
  }

  return defaultFlags;
};
